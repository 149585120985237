import Grid from '@mui/material/Unstable_Grid2';
import { IconButton, Radio, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React from 'react';
import RoleHeader from './RoleHeader';

type UserCommunityAccessTabProps = {
    selectedCommunities: { communityId: number; roleId: number }[];
    selectCommunity: (community: { communityId: number; roleId: number }) => void;
    deselectCommunity: (id: number) => void;
    companyRoles: any[];
    companyCommunities: any[];
};

const UserCommunityAccessTab = ({
    selectedCommunities,
    selectCommunity,
    deselectCommunity,
    companyRoles,
    companyCommunities,
}: UserCommunityAccessTabProps) => {
    return (
        <Grid container marginTop={2}>
            <Grid container direction={'column'} spacing={2} marginRight={6}>
                <Grid>Communities</Grid>
                <Grid container direction={'column'}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                {companyRoles?.map((role) => (
                                    <TableCell key={role.id}>
                                        <RoleHeader role={role} />
                                    </TableCell>
                                ))}
                                <TableCell>Remove Access</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companyCommunities?.map((community) => {
                                return (
                                    <TableRow key={community.id}>
                                        <TableCell>{community.name}</TableCell>
                                        {companyRoles?.map((role) => (
                                            <TableCell key={`${community.id}_${role.id}`} padding={'checkbox'} align={'center'}>
                                                <Radio
                                                    size={'small'}
                                                    checked={
                                                        selectedCommunities?.some((x) => x.communityId === community.id && x.roleId === role.id) ??
                                                        false
                                                    }
                                                    name={`access_level_radio_${community.id}`}
                                                    onChange={() => selectCommunity({ communityId: community.id, roleId: role.id })}
                                                />
                                            </TableCell>
                                        ))}
                                        <TableCell padding={'checkbox'} align={'center'}>
                                            <IconButton size={'small'} onClick={() => deselectCommunity(community.id)}>
                                                <ClearRoundedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserCommunityAccessTab;
