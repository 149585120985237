import Grid from '@mui/material/Unstable_Grid2';
import MultiselectListBox from '../../MultiselectListBox';
import { useMemo, useState } from 'react';

type UserReportsAccessTabProps = {
    selectedReportMetadataIds: any;
    setSelectedReportMetadataIds: (reports: number[]) => void;
    companyReports: any[];
};

const UserReportsAccessTab = ({ selectedReportMetadataIds = [], setSelectedReportMetadataIds, companyReports }: UserReportsAccessTabProps) => {
    const reports = useMemo(() => companyReports.filter((x) => !x.isDashboard), [companyReports]);
    const dashboards = useMemo(() => companyReports.filter((x) => x.isDashboard), [companyReports]);

    const [selectedReportIds, setSelectedReportIds] = useState(reports.filter((x) => selectedReportMetadataIds.includes(x.id)).map((x) => x.id));
    const [selectedDashboardIds, setSelectedDashboardIds] = useState(
        dashboards.filter((x) => selectedReportMetadataIds.includes(x.id)).map((x) => x.id),
    );

    const selectReportIds = (reportIds: number[]) => {
        const newSelectedReportMetadataIds = [...selectedDashboardIds, ...reportIds];
        setSelectedReportMetadataIds(newSelectedReportMetadataIds);
        setSelectedReportIds([...reportIds]);
    };

    const selectDashboardIds = (dashboardIds: number[]) => {
        const newSelectedReportMetadataIds = [...selectedReportIds, ...dashboardIds];
        setSelectedReportMetadataIds(newSelectedReportMetadataIds);
        setSelectedDashboardIds([...dashboardIds]);
    };

    return (
        <Grid container marginTop={2}>
            <Grid container direction={'column'} spacing={2} marginRight={6}>
                <Grid>Reports</Grid>
                <Grid>
                    <MultiselectListBox
                        options={reports.map((x) => {
                            return { label: x.reportName, value: x.id };
                        })}
                        selectedOptions={selectedReportIds ?? []}
                        setSelectedOptions={selectReportIds}
                    />
                </Grid>
            </Grid>
            <Grid container direction={'column'} spacing={2}>
                <Grid>Dashboards</Grid>
                <Grid>
                    <MultiselectListBox
                        options={dashboards.map((x) => {
                            return { label: x.reportName, value: x.id };
                        })}
                        selectedOptions={selectedDashboardIds ?? []}
                        setSelectedOptions={selectDashboardIds}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserReportsAccessTab;
