import { Badge, Button, IconButton, Menu, Stack, TextField, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useCommunities from '../../hooks/useCommunities';
import MultiselectListBox from '../MultiselectListBox';
import useMediaQuery from '@mui/material/useMediaQuery';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { useApplicationContext } from '../../ApplicationContext';
import { useReportContext } from '../../contexts/ReportContext';

const GlobalCommunityFilter = () => {
    const { selectedCompanyId } = useApplicationContext();
    const { globalFilter, setGlobalFilterValue } = useReportContext();
    const { communities } = useCommunities(true);
    const [selectedCommunities, setSelectedCommunities] = useState<any>(globalFilter.communities || []);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setSelectedCommunities([]);
    }, [selectedCompanyId]);

    useEffect(() => {
        setSelectedCommunities(globalFilter.communities?.map((c) => c.id) || []);
    }, [globalFilter.communities]);

    // TODO: move apply button out when we have more than one filter and have this component onChange the global filter
    const apply = () => {
        setAnchorEl(null);
        setGlobalFilterValue(
            'communities',
            selectedCommunities.map((id) => communities.find((c) => c.id === id)),
        );
    };

    return (
        <>
            {isMobile ? (
                <Badge badgeContent={selectedCommunities.length} invisible={selectedCommunities.length === 0} color="primary">
                    <IconButton
                        size={'small'}
                        disabled={communities.length === 0}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                        aria-label="community-select"
                        color={'primary'}
                    >
                        <FilterListRoundedIcon fontSize={'medium'} />
                    </IconButton>
                </Badge>
            ) : (
                <TextField
                    sx={{ width: { xs: 150, md: 150 } }}
                    label="Communities"
                    aria-label="community-select"
                    size={'small'}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        setAnchorEl(event.currentTarget);
                    }}
                    value={
                        selectedCommunities.length === 1
                            ? communities.find((c) => c.id === selectedCommunities[0])?.name
                            : selectedCommunities.length === 0
                            ? 'All'
                            : `${selectedCommunities.length} Communities`
                    }
                />
            )}
            <Menu
                disableAutoFocusItem
                id="community-filter-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'user-avatar',
                }}
            >
                <MultiselectListBox
                    listContainerProps={{
                        sx: {
                            maxHeight: '350px',
                            overflow: 'auto',
                        },
                    }}
                    containerStyle={{ border: 'none' }}
                    options={communities.map((c) => ({ label: c.name, value: c.id }))}
                    selectedOptions={selectedCommunities}
                    setSelectedOptions={setSelectedCommunities}
                />
                <Stack direction={'row'} justifyContent={'space-between'} sx={{ p: 2 }}>
                    <Button variant={'outlined'} onClick={() => setAnchorEl(null)}>
                        Cancel
                    </Button>
                    <Button variant={'contained'} onClick={() => apply()}>
                        Apply
                    </Button>
                </Stack>
            </Menu>
        </>
    );
};

export default GlobalCommunityFilter;
