import { useEffect, useState } from 'react';
import axios from 'axios';
import { useApplicationContext } from '../ApplicationContext';

type UseCommunitiesArgs = (requireUserAccess?: boolean) => { communities: any[] };

const useCommunities: UseCommunitiesArgs = (requireUserAccess = false) => {
    const { selectedCompanyId, isOwner } = useApplicationContext();
    const [communities, setCommunities] = useState([]);

    useEffect(() => {
        const url = `/Community`;
        axios
            .get(url, {
                params: { selectedCompanyId, isOwner, requireUserAccess },
            })
            .then((response) => {
                setCommunities(response.data);
            });
    }, [isOwner, selectedCompanyId, setCommunities, requireUserAccess]);

    return { communities };
};

export default useCommunities;
