import Grid from '@mui/material/Unstable_Grid2';
import { Box, Popover, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import React, { useState } from 'react';

const RoleHeader = ({ role }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Grid container wrap={'nowrap'} alignItems={'center'}>
            <Grid>
                <Typography sx={{ textAlign: 'center' }}>{`${role.roleName} Access`}</Typography>
            </Grid>
            {role.roleDescription ? (
                <Grid>
                    <Box
                        aria-owns={open ? `role-description-popover-${role.id}` : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <InfoOutlined fontSize={'small'} />
                    </Box>
                    <Popover
                        id={`role-description-popover-${role.id}`}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography sx={{ p: 1 }}>{role.roleDescription}</Typography>
                    </Popover>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default RoleHeader;
